import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"

import SEO from "../components/seo"
import Layout from "../components/layout"
import PageHeader from "../components/PageHeader"
import Vestry from "../components/Vestry"

import ParishPhoto from "../images/parish_photo.jpg"
import FrSteve from "../images/frsteve.jpg"
import Terri from "../images/terri.jpg"
import Mike from "../images/mike.jpg"
import Kathy from "../images/kathy.jpg"

import { PageHeaderText, InfoContainerEven, InfoContainerOdd, InfoHeader, Info, SubHeading } from '../components/common'

const TitleImage = styled(Grid)`
  height: 35vh;
  width: 100%;
  background: linear-gradient(
      180deg,
      rgba(10, 10, 10, 0.61) 35.42%,
      rgba(51, 121, 200, 0.85) 99.48%
    ),
    url(${ParishPhoto});
  background-size: cover;
  display: flex;
`
const Center = styled(Grid)`
  margin: Auto;
  min-height: 40vh;
  width: 90%;
  display: flex;
`
const RightInfo = styled.div`
    width: 82%;
    text-align: left;
    height: auto;

    color: #F2F2F2;
    font-size: 25px:
    font-weight: bold;
    display: inline-block;
    margin: auto 0 auto 100px;
`
const FrSteveImg = styled.div`
  height: 35vh;
  width: 18%;
  float: left;
  background: url(${FrSteve});
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  margin: auto 0;
`
const TerriImg = styled.div`
  height: 35vh;
  min-width: 18%;
  float: left;
  background: url(${Terri});
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  margin: auto 0;
`
const KathyImg = styled.div`
  height: 35vh;
  width: 18%;
  float: left;
  background: url(${Kathy});
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  margin: auto 0;
`
const MikeImg = styled.div`
  height: 35vh;
  width: 18%;
  float: left;
  background: url(${Mike});
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  margin: auto 0;
`

const Staff = () => (
  <Layout>
    <SEO title="Staff" />
    <Grid container>
      <TitleImage>
        <PageHeaderText>Our Staff</PageHeaderText>
      </TitleImage>
      <InfoContainerEven>
        <Center>
          <FrSteveImg></FrSteveImg>
          <RightInfo>
            <InfoHeader>Rector</InfoHeader>
            <SubHeading>Fr. Stephen R. Capitelli</SubHeading>
            <Info>
              Fr. Steve began his ministry at St. Alban’s July 1st, 2013. He and
              his wife Cathy are natives of Peoria Illinois. They moved to
              Wisconsin in 2004 to attend Nashotah House Seminary. Cathy is a
              Registered Nurse. Fr. Steve worked for twenty-five years in the
              printing and packaging industry and for several years as an
              Organizer and Lobbyist for the Service Employees International
              Union, Local 880. He earned his B.S. degree from the University of
              the State of New York and his Master of Divinity degree from
              Nashotah House Theological Seminary in 2007. He was ordained to
              the priesthood of June 30, 2007 and served as Priest-in-Charge of
              St. John in the Wilderness Episcopal Church in Elkhorn Wisconsin
              from 2007 to 2013.
            </Info>
          </RightInfo>
        </Center>
      </InfoContainerEven>
      <InfoContainerOdd>
        <Center>
          <KathyImg></KathyImg>
          <RightInfo>
            <InfoHeader>Parish Secretary</InfoHeader>
            <SubHeading>Kathy Sonier</SubHeading>
            <Info>

            </Info>
          </RightInfo>
        </Center>
      </InfoContainerOdd>
      <InfoContainerEven>
        <Center>
          <MikeImg></MikeImg>
          <RightInfo>
            <InfoHeader>Sexton</InfoHeader>
            <SubHeading>Mike Knapp</SubHeading>
            <Info>

            </Info>
          </RightInfo>
        </Center>
      </InfoContainerEven>
      <InfoContainerOdd>
        <Center>
          <TerriImg></TerriImg>
          <RightInfo>
            <InfoHeader>Music Minister/Organist</InfoHeader>
            <SubHeading>Terri Grenier</SubHeading>
            <Info>
            </Info>
          </RightInfo>
        </Center>
      </InfoContainerOdd>
      <Vestry />
    </Grid>
  </Layout>
)

export default Staff
