import React from "react"
import styled from "styled-components"

import Grid from "@material-ui/core/Grid"
import { PageHeaderText, SubHeading } from '../components/common'

const VestryContainer = styled(Grid)`
  height: 65vh;
  background: #0D0D0D;
  width: 100%;
  display: flex;
`
const VestryHeaderContainer = styled(Grid)`
  width: 100%;
  height: 175px;
  margin-top: 20px;
  padding: 10px;
`
const VestryInfoContainer = styled(Grid)`
  height: 50%;
  width: 80%;
  margin: 0 auto;
  display: flex;
`
const VestryPersonContainer = styled(Grid)`
  text-align: center;
  width: 25%;
  display: inline;
`
const Placeholder = styled.div`
  width: calc(8vh + (14 - 8) * ((100vw - 300px) / (1600 - 300)));
  height: calc(8vh + (14 - 8) * ((100vw - 300px) / (1600 - 300)));
  background: white;
  margin: 0 auto;
  border-radius: calc(4vh + (7 - 4) * ((100vw - 300px) / (1600 - 300)));
`
const PersonHeader = styled.h1`
  color: #74B9FF;
  font-size: calc(12px + (24 - 12) * ((100vw - 300px) / (1600 - 300)));
  text-align: center;
  min-height: 25px;
`
const PersonSubHeading = styled.h1`
  color: #BB2D31;
  font-size: calc(10px + (20 - 10) * ((100vw - 300px) / (1600 - 300)));
  text-align: center;
`
const Vestry = () => (
    <VestryContainer container>
        <VestryHeaderContainer>
            <PageHeaderText>
                The Vestry
            </PageHeaderText>
            <SubHeading>
                Basic information about the vestry ex: what they do, why they do it, how they're doin, etc.
            </SubHeading>
        </VestryHeaderContainer>
        <VestryInfoContainer>
            <VestryPersonContainer>
              <PersonHeader>
                  Senior Warden
              </PersonHeader>
              <PersonSubHeading>
                  Kathy Sonier
              </PersonSubHeading>
            </VestryPersonContainer>
            <VestryPersonContainer>
              <PersonHeader>
                  Junior Warden
              </PersonHeader>
              <PersonSubHeading>
                  Ron Hegwood
              </PersonSubHeading>
            </VestryPersonContainer>
            <VestryPersonContainer>
              <PersonHeader>
                  Treasurer
              </PersonHeader>
              <PersonSubHeading>
                  Ron Hegwood
              </PersonSubHeading>
            </VestryPersonContainer>
            <VestryPersonContainer>
              <PersonHeader>
                  Clerk of the Vestry
              </PersonHeader>
              <PersonSubHeading>
                  Steve Haynes
              </PersonSubHeading>
            </VestryPersonContainer>
        </VestryInfoContainer>
    </VestryContainer>
)

export default Vestry